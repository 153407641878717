<template>
  <div class="location">
    <div
      style="display:flex; gap: 20px; margin-bottom: 18px;"
    >
      <vue-select
        v-model="location"
        :options="getLocationsMoving"
        label="locationName"
        :reduce="(e) => e.locationId"
        :placeholder="$t('SelectLocation')"
        style="width: 45%;"
        :class="{'button--disabled': !articleNumber}"
        @input="filterByLocation"
      />
    </div>
    <div style="height: 50vh; overflow-y: scroll">
      <table
        ref="exportable_table"
        class="team_table"
        :class="{'button--disabled': !articleNumber}"
      >
        <thead style="position: sticky; top: -2px">
          <tr>
            <th>
              {{ $t('Location') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('AddToOrder') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getTotalStockGroupByLocationDedication?.productInStockAmountGroupedByLocationResponseDto?.length > 0">
          <tr
            v-for="(article, index) in getTotalStockGroupByLocationDedication.productInStockAmountGroupedByLocationResponseDto"
            :key="index"
          >
            <td>{{ $t(article.locationName) }}</td>
            <td>{{ $t(article.totalAmountInStock) }}</td>
            <td>
              <button
                v-b-modal.add-to-order
                class="button"
                @click="addToOrderer(article.locationId)"
              >
                {{ $t('AddToOrder') }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              style="padding: 16px 9px !important;"
              colspan="3"
            >
              {{ $t('NoArticleLocation') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="height: 50vh; overflow-y: scroll">
      <p style="font-weight: 600">{{ $t('DedicatedLocation') }}</p>
      <table
        ref="exportable_table"
        class="team_table"
        :class="{'button--disabled': !articleNumber}"
      >
        <thead style="position: sticky; top: -2px">
          <tr>
            <th>
              {{ $t('Location') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('AddToOrder') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getTotalStockGroupByLocationDedication?.productInStockAmountGroupedByDedicationLocationResponseDto?.length > 0">
          <tr
            v-for="(article, index) in getTotalStockGroupByLocationDedication.productInStockAmountGroupedByDedicationLocationResponseDto"
            :key="index"
          >
            <td>{{ $t(article.locationName) }}</td>
            <td>{{ $t(article.totalAmountInStock) }}</td>
            <td>
              <button
                v-b-modal.add-to-order
                class="button"
                @click="addToOrderer(article.locationId)"
              >
                {{ $t('AddToOrder') }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              style="padding: 16px 9px !important;"
              colspan="3"
            >
              {{ $t('NoArticleLocation') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmationModal
      @onSubmit="addToOrder"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmationModal from './modals/ConfrimationModal.vue'

export default {
  components: {
    ConfirmationModal,
  },
  props: ['articleNumber'],
  data() {
    return {
      location: null,
      locationId: null,
    }
  },
  computed: {
    ...mapGetters(['getLocationsMoving', 'getTotalStockGroupByLocationDedication']),
  },
  mounted() {
    this.movingLocations()
  },
  methods: {
    ...mapActions(['totalStockGroupedByLocationDedication', 'movingLocations']),
    async filterByLocation() {
      this.totalStockGroupedByLocationDedication({ articleNumber: this.articleNumber.articleNumber, locationId: this.location })
    },
    addToOrderer(value) {
      this.locationId = value
    },
    addToOrder() {
      const objekti = {
        articleNumber: this.articleNumber.articleNumber,
        price: this.articleNumber.price != null && this.articleNumber.price != '' ? this.articleNumber.price : 0,
        discount: 0,
        paidAmount: 0,
        currency: '€',
        discountType: '€',
        taxRate: 0,
        size: null,
        priceNotes: null,
        paidNotes: null,
        locationId: this.locationId,
        discountedPrice: this.articleNumber.price != null && this.articleNumber.price != '' ? this.articleNumber.price : 0,
        showDiscountForm: false,
      }
      this.$emit('addToOrder', objekti)
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 10px 9px !important;
}
.location {
  width: 45%;
}
@media screen and (max-width: 1200px) {
  .location {
    width: 100%;
  }
}
</style>
